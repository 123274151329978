import { Tabs } from "@mantine/core";
import {
  IconDatabase,
  IconBucket,
  IconListSearch,
  IconBrackets,
} from "@tabler/icons";
import { Sizing } from "realm/types";
import { StatsCard } from "../components/stats-card";
import StatComponentTopCollections from "./top-collections";
import StatComponentTopDatabases from "./top-databases";
import StatComponentTopIndexes from "./top-indexes";
import StatComponentTopOperations from "./top-operations";

const StatsComponentTopNTabs = (props: { data: Sizing }) => {
  const { data } = props;
  return (
    <StatsCard title={"Top N"}>
      <Tabs defaultValue="databases">
        <Tabs.List>
          <Tabs.Tab value="databases" icon={<IconDatabase size={14} />}>
            Databases
          </Tabs.Tab>
          <Tabs.Tab value="collections" icon={<IconBucket size={14} />}>
            Collections
          </Tabs.Tab>
          <Tabs.Tab value="indexes" icon={<IconListSearch size={14} />}>
            Indexes
          </Tabs.Tab>
          <Tabs.Tab value="operations" icon={<IconBrackets size={14} />}>
            Operations
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="indexes" pt="xs">
          <StatComponentTopIndexes
            data={data.semanticParse}
            nIndexes={data.data.nIndexes}
            totalIndexSize={data.data.totalIndexSize}
          />
        </Tabs.Panel>

        <Tabs.Panel value="databases" pt="xs">
          <StatComponentTopDatabases data={data} />
        </Tabs.Panel>

        <Tabs.Panel value="collections" pt="xs">
          <StatComponentTopCollections
            data={data.semanticParse}
            nCollections={data.data.nCollections}
            totalCollectionSize={data.data.totalDataSize}
          />
        </Tabs.Panel>

        <Tabs.Panel value="operations" pt="xs">
          <StatComponentTopOperations data={data.data} />
        </Tabs.Panel>
      </Tabs>
    </StatsCard>
  );
};

export default StatsComponentTopNTabs;
