import { Box, createStyles, Group, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SizingData } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import DatabaseDetailsTable from "./database-details-table";

const useStyles = createStyles((theme) => ({
  chevron: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: `transform 200ms ease`,
    width: 16,
    minWidth: 16,

    "&[data-rotate]": {
      transform: "rotate(180deg)",
    },
  },

  details: {
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  blueishRow: { color: theme.colors.blue[6] },
}));

const PAGE_SIZE = 5;

const StatDatabaseDeepDive = (props: { data: SizingData }) => {
  const { data } = props;

  const { classes } = useStyles();
  const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "dataSize",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(
    data.databaseStats.slice(0, PAGE_SIZE)
  );

  useEffect(() => {
    const sortedData = sortBy(data.databaseStats, sortStatus.columnAccessor);
    setRecords(
      sortStatus.direction === "desc" ? sortedData.reverse() : sortedData
    );
    setPage(1);
    setExpandedRecordIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  return (
    <DataTable
      mt="sm"
      withBorder
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => {
        setPage(p);
        setExpandedRecordIds([]);
      }}
      records={records.slice(PAGE_FROM, PAGE_TO)}
      totalRecords={data.databaseStats.length}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      columns={[
        {
          accessor: "db",
          title: "Database",
          sortable: true,
          render: ({ db }) => (
            <Group>
              <IconChevronDown
                className={classes.chevron}
                data-rotate={db === expandedRecordIds[0] ? true : undefined}
              />

              <Text>{db.toLocaleString()}</Text>
            </Group>
          ),
        },
        { accessor: "collections", sortable: true, textAlignment: "center" },
        {
          accessor: "dataSize",
          sortable: true,
          textAlignment: "center",
          render: ({ dataSize }) => {
            return formatBytes(dataSize);
          },
        },
        {
          accessor: "storageSize",
          sortable: true,
          textAlignment: "center",
          render: ({ storageSize }) => {
            return formatBytes(storageSize);
          },
        },
        {
          accessor: "indexSize",
          sortable: true,
          textAlignment: "right",
          render: ({ indexSize }) => {
            return formatBytes(indexSize);
          },
        },
      ]}
      idAccessor="db"
      // records={records}
      rowExpansion={{
        expanded: {
          recordIds: expandedRecordIds,
          onRecordIdsChange: setExpandedRecordIds,
        },
        content: ({ record }) => (
          <Box className={classes.details}>
            <DatabaseDetailsTable collections={record.collectionstats} />
          </Box>
        ),
      }}
      rowClassName={({ db }) =>
        db === expandedRecordIds[0] ? classes.blueishRow : undefined
      }
    />
  );
};

export default StatDatabaseDeepDive;
