import {
  Title,
  SimpleGrid,
  Button,
  LoadingOverlay,
  Tabs,
  Text,
  Stack,
} from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { Prism } from "@mantine/prism";
import { IconBraces, IconChecklist, IconListCheck } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { json, useParams } from "react-router";
import { getSizing } from "realm/datahooks";
import ErrorPage404 from "ui/layout/error-404";
import SizingDetailsTab from "./sizing-details-tab";
import SizingSummaryTab from "./sizing-summary-tab";

import { StatComponentSizingDetails } from "../stat-components";

function ViewSizingPage() {
  const params = useParams();
  const [showRawData, setShowRawData] = useState(false);

  const [title, setTitle] = useState("Sizing Tool");
  useDocumentTitle(title);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["sizing", params.id],
    queryFn: () => getSizing(params.id!),
    staleTime: Infinity,
    useErrorBoundary: true,
    onSuccess: (data) => {
      setTitle(`${data?.accountName}: ${data?.clusterName}` || "TBD");
    },
  });

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (isError) {
    throw json({ description: "Not Found" }, { status: 404 });
  }

  if (!data) {
    return <ErrorPage404 />;
  }

  if (data.data.shardingVersion) {
    return (
      <>
        <Title>View Sizing</Title>
        <Text align="center" mt="md">
          Unfortunately, support for sharded clusters are not YET implemented.
          For now, please accept a visual of the JSON
        </Text>
        <Stack align="center" spacing={4}>
          <Button mt={"md"} onClick={() => setShowRawData(!showRawData)}>
            Toggle RAW JSON
          </Button>
          <Text color="dimmed">Caution: Large Files may impact rendering</Text>
        </Stack>
        {showRawData && (
          <Prism withLineNumbers language={"json"} mt="md">
            {JSON.stringify(data, null, 2)}
          </Prism>
        )}
      </>
    );
  }

  return (
    <>
      <Title>View Sizing</Title>
      <SimpleGrid>
        <StatComponentSizingDetails data={data} />
      </SimpleGrid>

      <Tabs defaultValue="summary" mt="md">
        <Tabs.List grow>
          <Tabs.Tab value="summary" icon={<IconChecklist size={14} />}>
            Summary
          </Tabs.Tab>
          <Tabs.Tab value="details" icon={<IconListCheck size={14} />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="json" icon={<IconBraces size={14} />}>
            Raw JSON
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="summary" pt="xs">
          <SizingSummaryTab data={data} />
        </Tabs.Panel>

        <Tabs.Panel value="details" pt="xs">
          <SizingDetailsTab data={data} />
        </Tabs.Panel>

        <Tabs.Panel value="json" pt="xs">
          <Stack align="center" spacing={4}>
            <Button mt={"md"} onClick={() => setShowRawData(!showRawData)}>
              Toggle RAW JSON
            </Button>
            <Text color="dimmed">
              Caution: Large Files may impact rendering
            </Text>
          </Stack>
          {showRawData && (
            <Prism withLineNumbers language={"json"} mt="md">
              {JSON.stringify(data, null, 2)}
            </Prism>
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
}

export default ViewSizingPage;
