import {
  Container,
  Title,
  Accordion,
  createStyles,
  LoadingOverlay,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getFrequentlyAskedQuestions } from "realm/datahooks";
import MarkDown from "ui/components/markdown";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

function FaqPage(props: { title: string }) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const { data, isLoading } = useQuery({
    queryKey: ["faqs"],
    queryFn: () => getFrequentlyAskedQuestions(),
  });

  const { classes } = useStyles();

  if (isLoading) {
    return (
      <LoadingOverlay visible={true} transitionDuration={500} overlayBlur={2} />
    );
  }

  return (
    <Container size="sm">
      <Title align="center" className={classes.title}>
        Frequently Asked Questions
      </Title>

      <Accordion variant="separated">
        {data?.faqs.map((faq, i) => {
          return (
            <Accordion.Item className={classes.item} key={i} value={`${i}`}>
              <Accordion.Control>{faq.q}</Accordion.Control>
              <Accordion.Panel>
                <MarkDown md={faq.a} />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}

export default FaqPage;
