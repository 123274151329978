import { Box, Group, RingProgress, Stack, Text } from "@mantine/core";
import { Sizing } from "realm/types";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import { formatBytes } from "utils/format-bytes";
import StatDatabaseDeepDive from "./database-deepdive";
import DownloadCSVButton from "../components/download-csv-button";
import { getCollectionCSVData } from "realm/datahooks";

const StatComponentTopDatabases = (props: { data: Sizing }) => {
  const { data } = props;
  const { data: dbs } = data;

  const TOP_N_LIMIT = 5;

  const topNDbSize = dbs.databaseStats
    .slice(0, TOP_N_LIMIT)
    .reduce((partialSum, a) => partialSum + a.dataSize, 0);
  const topNPercentage = (topNDbSize / dbs.totalDataSize) * 100;

  return (
    <>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            unit="byte"
            fillPercent={100}
            data={dbs.databaseStats.slice(0, TOP_N_LIMIT).map((item) => {
              return { label: item.db.toLocaleString(), value: item.dataSize };
            })}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top Databases - ${formatBytes(topNDbSize)}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Databases - ${formatBytes(
                  dbs.totalDataSize - topNDbSize
                )}`,
              },
            ]}
          />
          <Text>Database Count: {dbs.nDatabases}</Text>
          <Text>Total Size: {formatBytes(dbs.totalDataSize)}</Text>
        </Stack>
      </Group>

      <StatDatabaseDeepDive data={dbs} />
      <Group position="right" mt="sm">
        <DownloadCSVButton
          datas={() => getCollectionCSVData(data._id!)}
          filename={`${data.accountName}_${data.clusterName}`} // Future: May want to use React Query to leverage caching, or process CSV locally.
        />
      </Group>
    </>
  );
};

export default StatComponentTopDatabases;
