import { createFormContext } from "@mantine/form";

export const initial_sizing_values = {
  ts: new Date(),
  clusterName: [""],
  opportunityName: "",
  opportunityId: "",
  accountName: "",
  accountOwner: "",
  sfid: "",
  ownerEmail: "",
  ownerFullName: "",
  data: {} as any, // TODO: Create Type once the sizing script is completed
};

// You can give context variables any name
export const [SizingFormProvider, useSizingFormContext, useSizingForm] =
  createFormContext<typeof initial_sizing_values>();
