import { SimpleGrid } from "@mantine/core";
import { Sizing } from "realm/types";
import {
  StatComponentBinaryDetails,
  StatComponentClusterProfile,
  StatComponentServerDetails,
  StatComponentWorkingSet,
  StatsComponentTopNTabs,
  StatComponentDataSizes,
} from "../stat-components";

const SizingDetailsTab = (props: { data: Sizing }) => {
  const { data } = props;
  const talkingPoints = data.talkingPoints || [];
  return (
    <>
      <SimpleGrid>
        <StatComponentClusterProfile
          talkingpoints={talkingPoints}
          data={data.data}
        />
      </SimpleGrid>
      <SimpleGrid
        cols={3}
        mt="md"
        breakpoints={[
          { maxWidth: 1000, cols: 2, spacing: "sm" },
          { maxWidth: 700, cols: 1, spacing: "sm" },
        ]}
      >
        <StatComponentBinaryDetails
          data={data.data}
          talkingPoints={talkingPoints}
        />
        <StatComponentServerDetails
          data={data.data}
          talkingpoints={talkingPoints}
        />
        <StatComponentDataSizes data={data.data} />
      </SimpleGrid>

      <SimpleGrid mt="md">
        <StatComponentWorkingSet
          totalDataSizeMB={data.data.totalDataSize / 1000 ** 2}
          totalIndexSizeMB={data.data.totalIndexSize / 1000 ** 2}
          memSizeMB={data.data.serverInfo.memSizeMB}
          connections={data.data.serverInfo.connections.current}
          wiredtigerCacheSizeMB={
            data.data.serverInfo.wiredtigerCacheSize / 1000 ** 2
          }
        />
        <StatsComponentTopNTabs data={data} />
      </SimpleGrid>
    </>
  );
};

export default SizingDetailsTab;
