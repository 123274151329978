import { SimpleGrid } from "@mantine/core";
import {
  SummaryClusterSizing,
  SummaryTalkingPoints,
} from "features/view-sizing-summary";
import { Sizing } from "realm/types";
import { StatsCard } from "../components/stats-card";

const SizingSummaryTab = (props: { data: Sizing }) => {
  const { data } = props;
  const talkingPoints = data.talkingPoints || [];
  return (
    <>
      <SimpleGrid>
        <StatsCard title={"Suggested Sizing"}>
          <SummaryClusterSizing suggestedSizing={data.sizing} />
        </StatsCard>
      </SimpleGrid>
      <SimpleGrid mt="md">
        <StatsCard title={"Points of Conversation"}>
          <SummaryTalkingPoints
            id={data._id!}
            flags={talkingPoints}
          />
        </StatsCard>
      </SimpleGrid>
    </>
  );
};

export default SizingSummaryTab;
