import { getCurrentUser } from "./authentication";
import {
  Account,
  ErrorMessage,
  FaqData,
  Feedback,
  GenerateSizingInput,
  Opportunities,
  TalkingPoint,
  SearchSizing,
  Sizing,
} from "./types";

export const searchAccount = (searchTerm: string): Promise<Account[]> => {
  return getCurrentUser()!.functions.searchSalesForceAccount({ searchTerm });
};

export const searchSizedAccounts = (
  searchTerm: string
): Promise<SearchSizing[]> => {
  return getCurrentUser()!.functions.searchSizedAccounts({ searchTerm });
};

export const uploadSizing = (payload: Sizing) => {
  return getCurrentUser()!.functions.uploadNewSizing(payload);
};

export const getSizing = (_id: string): Promise<Sizing | null> => {
  return getCurrentUser()!.functions.getSizing(_id);
};

export const getSizingsBySfid = (props: {
  sfid: string;
  sizingOwner: string;
}): Promise<Sizing[]> => {
  return getCurrentUser()!.functions.getSizingsBySfid(props);
};

export const getAllSizings = (): Promise<Sizing[]> => {
  return getCurrentUser()!.functions.getAllSizings();
};

export const getClusterNames = (sfid: string): Promise<string[]> => {
  return getCurrentUser()!.functions.getClusterNames(sfid);
};

export const uploadFeedback = (payload: Feedback) => {
  return getCurrentUser()!.functions.uploadFeedback(payload);
};

export const uploadErrorMessage = (payload: ErrorMessage): Promise<any> => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return new Promise(() => {});
  } else {
    return getCurrentUser()!.functions.uploadErrorMessage(payload);
  }
};

export const getFeedback = (): Promise<Feedback[]> => {
  return getCurrentUser()!.functions.getAllFeedback();
};

export const getOpportunities = (sfid: string): Promise<Opportunities[]> => {
  if (sfid) {
    return getCurrentUser()!.functions.getOpportunities(sfid);
  } else {
    return new Promise((resolve, reject) => resolve([]));
  }
};

export const deleteSizing = (
  _id: string
): Promise<{ deletedCount: number }> => {
  return getCurrentUser()!.functions.deleteSizing(_id);
};

export const getCollectionCSVData = (
  _id: string
): Promise<
  {
    db: string;
    collection: string;
    dataSize: number;
    storageSize: number;
    avgObjSize: number;
    indexSize: number;
    nIndexes: number;
  }[]
> => {
  return getCurrentUser()!.functions.getCollectionCSVData(_id);
};

export const updateTalkingPoints = (props: {
  _id: string;
  payload: TalkingPoint[];
}) => {
  return getCurrentUser()!.functions.updateSizing({
    _id: props._id,
    payload: { talkingPoints: props.payload },
  });
};

export const getFrequentlyAskedQuestions = (): Promise<FaqData> => {
  return getCurrentUser()!.functions.getConfigData({
    type: "documentation",
    k: "information",
    v: "tool",
  });
};

export const generateSizing = (payload: GenerateSizingInput) => {
  const { bestPracticeValues, ...args } = payload;
  return getCurrentUser()!.functions.generateSizing(args, bestPracticeValues);
};

export const updateSizingClusterName = (props: {
  _id: string;
  payload: string;
}) => {
  return getCurrentUser()!.functions.updateSizing({
    _id: props._id,
    payload: { clusterName: props.payload },
  });
};
