import { Stack, RingProgress, Text, Group, Box } from "@mantine/core";
import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SemanticParsedData } from "realm/types";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import { formatBytes } from "utils/format-bytes";

const PAGE_SIZE = 5;
const TOP_N_LIMIT = 5;

const StatComponentTopCollections = (props: {
  data?: SemanticParsedData;
  nCollections: number;
  totalCollectionSize: number;
}) => {
  const { data, nCollections, totalCollectionSize } = props;
  const { topCollectionsByDataSize } = data || {topCollectionsByDataSize: []};

  const totalTopCollectionSize = topCollectionsByDataSize
    .slice(0, TOP_N_LIMIT)
    .reduce((partialSum, a) => partialSum + a.dataSize, 0);
  const topNPercentage = (totalTopCollectionSize / totalCollectionSize) * 100;

  const indexedData = topCollectionsByDataSize.map((index, i) => {
    return { ...index, id: i };
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "dataSize",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(indexedData.slice(0, PAGE_SIZE));

  useEffect(() => {
    const data = sortBy(indexedData, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  return (
    <>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            unit="byte"
            fillPercent={100}
            data={topCollectionsByDataSize
              .map((item) => {
                return {
                  label: `${item.db}.${item.collection}`,
                  value: item.dataSize,
                };
              })
              .slice(0, 5)}
            // data={topNCollections}
            // limit={TOP_N_LIMIT}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top Collections - ${formatBytes(
                  totalTopCollectionSize
                )}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Collections - ${formatBytes(
                  totalCollectionSize - totalTopCollectionSize
                )}`,
              },
            ]}
          />
          <Text>Collection Count: {nCollections}</Text>
          <Text>Total Size: {formatBytes(totalCollectionSize)}</Text>
        </Stack>
      </Group>

      <DataTable
        mt="sm"
        withBorder
        withColumnBorders
        striped
        minHeight={200}
        columns={[
          {
            accessor: "db",
            title: "Namespace",
            // width: 280,
            render: ({ db, collection }) => {
              return `${db}.${collection}`;
            },
            ellipsis: true,
          },
          {
            accessor: "dataSize",
            sortable: true,
            width: 110,
            textAlignment: "right",
            render: ({ dataSize }) => {
              return formatBytes(dataSize, 1);
            },
          },
          {
            accessor: "storageSize",
            sortable: true,
            width: 135,
            textAlignment: "right",
            render: ({ storageSize }) => {
              return formatBytes(storageSize, 1);
            },
          },
        ]}
        totalRecords={indexedData.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        records={records.slice(PAGE_FROM, PAGE_TO)}
        idAccessor={"id"}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        noRecordsText="No Collection Information"
      />
    </>
  );
};

export default StatComponentTopCollections;
