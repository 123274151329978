import { Button, LoadingOverlay, Stack, Title } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import uploadFileToS3Bucket from "utils/s3-upload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getCurrentUser } from "realm/authentication";
import { uploadSizing } from "realm/datahooks";
import { FileDropZone } from "ui";
import { SearchAccount } from "../components/search-account";
import SelectOpportunity from "../components/select-opportunity";
import SizingInstructions from "../components/sizing-instructions";
import {
  SizingFormProvider,
  useSizingForm,
  initial_sizing_values,
} from "../providers/new-sizing-context";

function NewSizingPage(props: { title: string }) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  const [accountSelected, setAccountSelected] = useState("");
  const [file, setFile] = useState<FileWithPath | null>(null);

  const [isUploadingS3, setIsUploadingS3] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useMutation({ mutationFn: uploadSizing });

  const onSuccessUploadToMongoDB = async (data: any) => {
    await invalidateQueries();
    return data.toString();
  };

  const invalidateQueries = async () => {
    queryClient.invalidateQueries({
      queryKey: [
        "allSizings",
        {
          type: "ownerFullName",
          value: currentUser!.profile.name!,
        },
      ],
    });
  };

  const uploadToS3 = async (fileName: string) => {
    setIsUploadingS3(true);
    uploadFileToS3Bucket(file!, fileName).finally(() => {
      navigate(`/view/${fileName}`);
      setIsUploadingS3(false);
    });
  };

  const onErrorUpload = (error: unknown) => console.log("Error: ", error);

  const { mutateAsync: mutateUploadSizing, isLoading: isUploadingSizing } =
    mutation;

  const form = useSizingForm({
    initialValues: {
      ...initial_sizing_values,
      ownerEmail: currentUser!.profile.email || "",
      ownerFullName: currentUser!.profile.name || "",
    },

    validate: {
      accountName: (value) => (value ? null : "Cant be empty"),
      clusterName: (value) => (value[0] ? null : "Cant be empty"),
      opportunityName: (value) => (value[0] ? null : "Cant be empty"),
      data: (value) => (value.serverInfo ? null : "Cant be empty"),
    },
  });

  return (
    <SizingFormProvider form={form}>
      <LoadingOverlay
        visible={isUploadingSizing || isUploadingS3}
        transitionDuration={500}
        overlayBlur={2}
      />
      <form
        onSubmit={form.onSubmit((values) => {
          mutateUploadSizing({
            ...values,
            clusterName: values.clusterName[0],
          })
            .then(onSuccessUploadToMongoDB)
            .then((data) => uploadToS3(data))
            .catch(onErrorUpload);
        })}
      >
        <Stack spacing={0}>
          <Title>Start a New Sizing</Title>
          <SizingInstructions />
        </Stack>
        <SearchAccount
          {...{
            accountSelected,
            setAccountSelected,
          }}
        />
        <SelectOpportunity {...{ accountSelected }} />

        <FileDropZone file={file} setFile={setFile} />

        <Button mt="md" type="submit" disabled={!form.isValid()}>
          {!form.isValid() ? "Fill Form to Upload" : "Click to upload!"}
        </Button>
      </form>
    </SizingFormProvider>
  );
}

export default NewSizingPage;
