import { sortBy } from "lodash";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SemanticParsedData } from "realm/types";
import { formatBytes } from "utils/format-bytes";
import { Box, Group, RingProgress, Stack, Text } from "@mantine/core";
import StatsProgressCompare from "ui/components/stats-bar-charts";
import formatNumber from "utils/format-number";

const PAGE_SIZE = 5;

const StatComponentTopIndexes = (props: {
  data?: SemanticParsedData;
  nIndexes: number;
  totalIndexSize: number;
}) => {
  const { data, nIndexes, totalIndexSize } = props;
  const { topIndexesBySize, unusedIndexes } = data || {topIndexesBySize: [], unusedIndexes: []};

  const totalTopIndexSize = topIndexesBySize
    .slice(0, 5)
    .reduce((partialSum, a) => partialSum + a.indexSize, 0);

  // const totalUnusedIndexSize = unusedIndexes.reduce(
  //   (partialSum, a) => partialSum + a.indexSize,
  //   0
  // );

  // const totalUnusedIndexSize = (unusedIndexes ?? [])  
  // .reduce((partialSum: number, a: { indexSize: number }) => partialSum + a.indexSize, 0);  

  const totalUnusedIndexSize = (unusedIndexes as { indexSize: number }[])  
  .reduce((partialSum: number, a: { indexSize: number }) => partialSum + a.indexSize, 0);  


  
  const topNPercentage = (totalTopIndexSize / totalIndexSize) * 100;

  const indexedData = topIndexesBySize.map((index, i) => {
    return { ...index, id: i };
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "indexSize",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(indexedData.slice(0, PAGE_SIZE));

  useEffect(() => {
    const data = sortBy(indexedData, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const PAGE_FROM = (page - 1) * PAGE_SIZE;
  const PAGE_TO = PAGE_FROM + PAGE_SIZE;

  return (
    <>
      <Group position="center" noWrap>
        <Box w={500}>
          <StatsProgressCompare
            data={topIndexesBySize
              .map((item) => {
                return { label: item.index.name, value: item.indexSize };
              })
              .slice(0, 5)}
            fillPercent={95}
            unit={"byte"}
          />
        </Box>
        <Stack spacing={0} align="center">
          <RingProgress
            size={150}
            thickness={25}
            label={
              <Text color="blue" weight={700} align="center" size="sm">
                {topNPercentage.toFixed(1)}%
              </Text>
            }
            sections={[
              {
                value: topNPercentage,
                color: "teal.6",
                tooltip: `Top Indexes - ${formatBytes(totalTopIndexSize)}`,
              },
              {
                value: 100 - topNPercentage,
                color: "cyan.6",
                tooltip: `All Other Indexes - ${formatBytes(
                  totalIndexSize - totalTopIndexSize
                )}`,
              },
            ]}
          />

          <Text>
            Total Indexes: {nIndexes || "N/A"} or{" "}
            {formatBytes(totalIndexSize, 1)}
          </Text>
          <Text>
            Unused Indexes: {unusedIndexes.length} or{" "}
            {formatBytes(totalUnusedIndexSize, 1)}
          </Text>
        </Stack>
      </Group>
      <DataTable
        mt="sm"
        withBorder
        withColumnBorders
        minHeight={200}
        striped
        columns={[
          {
            accessor: "db",
            title: "Namespace",
            width: 280,
            render: ({ db, collection }) => {
              return `${db}.${collection}`;
            },
            ellipsis: true,
          },
          {
            accessor: "index.name",
            title: "Index",
            width: 440,
            render: ({ index }) => {
              return JSON.stringify(index.key);
            },
            ellipsis: true,
          },
          {
            accessor: "index.accesses.ops",
            title: "Accesses",
            sortable: true,
            width: 120,
            textAlignment: "right",
            render: ({ index }) => {
              return formatNumber(index.accesses.ops, 1);
            },
          },
          {
            accessor: "indexSize",
            title: "Size",
            sortable: true,
            width: 90,
            textAlignment: "right",
            render: ({ indexSize }) => {
              return formatBytes(indexSize, 1);
            },
          },
        ]}
        totalRecords={indexedData.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        records={records.slice(PAGE_FROM, PAGE_TO)}
        idAccessor={"id"}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        noRecordsText="No Index Information"
      />
    </>
  );
};

export default StatComponentTopIndexes;
