import { Group, useMantineTheme, Text } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { IconUpload, IconX, IconFileUpload } from "@tabler/icons";
import { useState } from "react";
import { formatBytes } from "utils/format-bytes";
import { useSizingFormContext } from "../providers/new-sizing-context";

interface FileDropZoneProps {
  file: FileWithPath | null;
  setFile: React.Dispatch<React.SetStateAction<FileWithPath | null>>;
}

function FileDropZone(props: FileDropZoneProps) {
  const FILE_SIZE = 13 * 1000 ** 2; // 13 MB

  const theme = useMantineTheme();
  const form = useSizingFormContext();

  const { file, setFile } = props;
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(false);

  const handleFileDrop = async (files: FileWithPath[]) => {
    setFileLoading(true);
    setFileError(false);
    var reader = new FileReader();
    reader.onload = function (e) {
      var contents = e.target!.result;
      setFile(files[0]);

      console.log(files[0]);
      console.log("File Path: ", files[0].path);

      try {
        const clean_contents = (contents as string).replaceAll(
          "$timestamp",
          "timestamp"
        );

        if (clean_contents.startsWith("[")) {
          throw new Error("Invalid JSON format");
        }

        form.setValues({
          clusterName: [files[0].name.split(".")[0]],
          data: JSON.parse(clean_contents),
        });
      } catch {
        setFileError(true);
      }
    };
    reader.readAsText(files[0]);
    setFileLoading(false);
  };

  return (
    <Dropzone
      onDrop={handleFileDrop}
      maxSize={FILE_SIZE}
      maxFiles={1}
      multiple={false}
      accept={["application/json", "text/javascript"]}
      loading={fileLoading}
      mt="md"
      sx={(theme) => ({
        "&[data-idle]": {
          color: file
            ? fileError
              ? theme.colors.red[6]
              : theme.colors.green[6]
            : "",
          border: file
            ? `2px solid ${
                fileError ? theme.colors.red[6] : theme.colors.green[6]
              }`
            : "",
        },
      })}
    >
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: 220, pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            size={50}
            stroke={1.5}
            color={
              theme.colors[theme.primaryColor][
                theme.colorScheme === "dark" ? 4 : 6
              ]
            }
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size={50}
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconFileUpload size={50} stroke={1.5} />
        </Dropzone.Idle>

        <div>
          {file ? (
            <>
              <Text size="xl" inline>
                File Selected: {file.name}
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                File Size: {formatBytes(file.size)}
              </Text>
              <Text>
                {fileError && "Invalid JSON - Check File and Try Again"}
              </Text>
            </>
          ) : (
            <>
              <Text size="xl" inline>
                Drag Sizing JSON File here or click to select file
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Only attach ONE file per sizing. Max File Size 10MB.
              </Text>
            </>
          )}
        </div>
      </Group>
    </Dropzone>
  );
}

export default FileDropZone;
